import _ from 'lodash'
import noUiSlider from 'nouislider'
import { Controller } from '@hotwired/stimulus'
import { scrollToDirectoryView, updateQueryString } from 'mp/helpers/directory_helper'

export default class extends Controller {
  static targets = ['slider', 'min', 'max']
  static values = { min: Number, max: Number, startFilter: Number, endFilter: Number, labelId: String }

  initialize() {
    this.debounceSubmit = _.debounce(function () {
      this.minTarget.form.requestSubmit();
      updateQueryString(this.minTarget.form);
      scrollToDirectoryView();
    }, 350);
  }

  connect() {
    const { maxValue, minValue, labelIdValue } = this;
    let selectedMin = this.startFilterValue || minValue;
    let selectedMax = this.endFilterValue || maxValue;

    noUiSlider.create(this.sliderTarget, {
      start: [selectedMin, selectedMax],
      connect: true,
      tooltips: true,
      range: {
          'min': minValue,
          'max': maxValue
      },
      format: {
        from: function(value) { return parseInt(value); },
        to: function(value) { return parseInt(value); }
      },
      handleAttributes: [{ 'aria-labelledby': labelIdValue }, { 'aria-labelledby': labelIdValue }],
      step: 1
    });

    if (this.startFilterValue && this.endFilterValue) {
      this.minTarget.value = this.startFilterValue
      this.maxTarget.value = this.endFilterValue
    }

    if (maxValue - minValue <= 1) {
      this.sliderTarget.setAttribute('disabled', '')
    }
    else {
      this.sliderTarget.noUiSlider.on('change', this.setFormValues.bind(this))
    }
  }

  setFormValues(e) {
    this.minTarget.value = e[0]
    this.maxTarget.value = e[1]
    this.debounceSubmit()
  }
}
