import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  requestSubmit(e) {
    e.preventDefault();

    fetch(
      e.params.path,
      {
        method: e.params.method,
        headers: { 'Content-Type': 'application/json', 'Accept': 'text/vnd.turbo-stream.html' },
        body: JSON.stringify({
          authenticity_token: document.querySelector('meta[name="csrf-token"]')?.content,
          listing_id: e.params.id
        })
      }
    )
    .then(response => {
      if (response.ok) {
        return response.text();
      }
    })
    .then(turboStream => Turbo.renderStreamMessage(turboStream))
  }
}
