import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const options = {
      persist: false,
	    createOnBlur: true,
      create: true,
      plugins: ['remove_button'],
      maxItems: null,
      onItemAdd: (value, item) => {
        if (!this.emailRegex.test(value)) {
          item.classList.add('invalid');
        }
      },
      render: {
        no_results: (data, escape) => null,
        option_create: (data, escape) => null
      }
    }

    this.tagsSelect = new TomSelect(this.inputTarget, options);
    this.tagsSelect.control_input.addEventListener('keydown', function(event) {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
      }
    });
  }
}
