import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.adjustListingCard();
    this.openRequestModal();
  }

  adjustListingCard() {
    document.querySelectorAll('[data-open-dialog]').forEach((opener) => {
      opener.addEventListener('click', (evt) => {
        const dialogTarget = evt.target.closest('[data-open-dialog]');
        const dialog = document.querySelector(`dialog[data-name=${dialogTarget.dataset.openDialog}]`);
        const listingCard = dialog.querySelector('.listing-card');
        this.listingFields = dialog.querySelectorAll('[name="listing_id"]');

        if (listingCard) {
          if(dialogTarget.dataset.showListingCard == 'true') {
            listingCard.classList.remove('hidden');
            listingCard.classList.add('visible');
            this.setListingFieldsValue(dialogTarget.dataset.listingId);
          }
          else {
            listingCard.classList.add('hidden');
            listingCard.classList.remove('visible');
            this.setListingFieldsValue();
            sessionStorage.removeItem('openRequestModal');
          }
        }
      });
    });
  }

  setListingFieldsValue(value = null) {
    if (this.listingFields.length) {
      this.listingFields.forEach(field => field.value = value);
    }
  }

  setQuoteRequestModalState(e) {
    if(e.currentTarget.dataset.openDialog != 'quote-request-modal') {
      sessionStorage.setItem('openRequestModal', 'quote-request-modal');
    }
  }

  openRequestModal() {
    if (sessionStorage.hasOwnProperty('openRequestModal')) {
      const dialog = document.querySelector(`dialog[data-name=${sessionStorage.getItem('openRequestModal')}]`);
      if (dialog) {
        dialog.showModal();
      }
      sessionStorage.removeItem('openRequestModal');
    }
  }
}
