import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  brandsPath = '/brands'
  modelsPath = '/models'
  typesPath = '/types'
  specsPath = '/specifications'

  static targets = ['brandSelect', 'currencySelect', 'modelSelect', 'typeSelect']

  connect() {
    this.initializeBrandsSelect();
    this.initializeModelsSelect();
    this.initializeTypesSelect();
    this.initializeCurrencySelect();
  }

  initializeBrandsSelect() {
    const options = {
      create: true,
      onInitialize: () => {
        this.loading = 1;

        fetch(`${this.brandsPath}`)
          .then((response) => response.json())
          .then((items) => {
            this.brandSelect.addOptions(items);
            this.loading = 0;
          });
      },
      load: (query, callback) => {
        fetch(`${this.brandsPath}?search=${query}`)
          .then((response) => response.json())
          .then((items) => {
            callback(items);
          })
          .catch(() => {
            callback();
          })
      },
      onItemAdd: (value) => {
        if (this.modelSelectTarget) {
          this.setModels(value);
        }
      },
      onItemRemove: () => {
        setTimeout(() => {
          if(this.brandSelect.items.length < 1) {
            this.modelSelect.clear(true);
            this.modelSelect.disable();
          }
        }, 100)
      },
    }

    this.brandSelect = new TomSelect(this.brandSelectTarget, options);
  }

  initializeModelsSelect() {
    const options = {
      create: true,
      onItemAdd: (_value, item) => {
        if (this.typeSelectTarget) {
          this.setListingType(item.dataset.listingTypeId);
          this.typeSelect.enable();
        }
      },
      onItemRemove: () => {
        setTimeout(() => {
          if(this.modelSelect.items.length < 1) {
            this.typeSelect.clear(true);
            this.typeSelect.disable();
          }
        }, 100)
      },
      render: {
        item: (data, escape) => {
          return `<div data-listing-type-id=${data.listing_type_id}>` + escape(data.text) + '</div>';
        }
      }
    }

    this.modelSelect = new TomSelect(this.modelSelectTarget, options);

    if(this.brandSelect.items.length < 1) {
      this.modelSelect.disable();
    }
  }

  initializeTypesSelect() {
    const options = {
      create: true,
      onInitialize: () => {
        this.loading = 1;

        fetch(`${this.typesPath}`)
          .then((response) => response.json())
          .then((items) => {
            this.typeSelect.addOptions(items);
            this.loading = 0;
          });
      },
      load: (query, callback) => {
        fetch(`${this.typesPath}?search=${query}`)
          .then((response) => response.json())
          .then((items) => {
            callback(items);
          })
          .catch(() => {
            callback();
          });
      },
      onItemAdd: (value) => {
        this.setSpecifications(value);
      },
      onItemRemove: () => {
        this.setSpecifications('');
      },
    }

    this.typeSelect = new TomSelect(this.typeSelectTarget, options);

    if(this.modelSelect.items.length < 1) {
      this.typeSelect.disable();
    }
  }

  initializeCurrencySelect() {
    if (!this.currencySelectTarget) { return; }

    const options = {
      create: false,
      controlInput: null,
      onDelete: () => false,
      render: {
        item: (data, escape) => {
          return '<div>' + escape(data.value.toUpperCase()) + '</div>';
        }
      }
    }

    this.currencySelect = new TomSelect(this.currencySelectTarget, options);
  }

  setListingType = async (id) => {
    const response = await fetch(`${this.typesPath}/${id}`, {
      headers: {
        Accept: 'application/json',
      }
    });
    const listingType = await response.json();

    if (listingType) {
      this.typeSelect.addOption(listingType);
      this.typeSelect.setValue(listingType.value);
    }
  }

  setModels = async (brand_id) => {
    if (brand_id.length && !this.modelSelectTarget) { return; }

    this.modelSelect.loading = 1;
    this.modelSelect.clear(true);
    this.modelSelect.clearOptions();

    const response = await fetch(`${this.modelsPath}?brand_id=${brand_id}`);
    const models = await response.json();

    this.modelSelect.enable();
    this.modelSelect.loading = 0;

    if (models.length) {
      this.modelSelect.addOptions(models);
      this.modelSelect.refreshOptions(false);
    }
  }

  setSpecifications = async (listing_type_id) => {
    fetch(`${this.specsPath}?listing_type_id=${listing_type_id}`)
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
