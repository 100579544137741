export function init() {
  document.querySelectorAll('.inline-tabs .nav-item').forEach((navItem) => {
    navItem.addEventListener('click', () => {
      const inlineTabs = navItem.closest('.inline-tabs');

      // Reset all nav items and icons
      inlineTabs.querySelectorAll('.nav-item').forEach((item) => {
        item.classList.remove('active');
        const icon = item.querySelector('[data-icon]');

        if (icon) {
          icon.classList.add('fa-regular');
          icon.classList.remove('fa-solid');
        }
      });

      // Hide all tab content
      inlineTabs.querySelectorAll('.tab-pane').forEach((tabPane) => {
        tabPane.classList.remove('active');
      });

      // Activate clicked nav item and corresponding tab content
      const icon = navItem.querySelector('[data-icon]');
      const tabPaneTarget = navItem.querySelector('[data-toggle-tab]')?.dataset.toggleTab;
      const tabContent = inlineTabs.querySelector(tabPaneTarget);
      if (icon) {
        icon.classList.remove('fa-regular');
        icon.classList.add('fa-solid');
      }

      if (tabContent) {
        tabContent.classList.add('active');
      }

      navItem.classList.add('active');
    });
  });
}
