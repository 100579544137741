// app/javascript/controllers/multi_step_form_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['step'];

  connect() {
    this.form = this.element;
    this.currentStepIndex = 0;
    this.setBackButton();
    this.showCurrentStep();
  }

  nextStep(e) {
    e.preventDefault();

    if (this.form.reportValidity() && this.currentStepIndex < this.stepTargets.length - 1) {
      this.currentStepIndex++;
      this.showCurrentStep();
    }
  }

  prevStep() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.showCurrentStep();
    }
  }

  showCurrentStep() {
    this.stepTargets.forEach((step, index) => {
      if (index === 0 && this.backButton) {
        this.backButton.classList.toggle('invisible');
      }

      step.classList.toggle('hidden', index !== this.currentStepIndex);
    });
  }

  setBackButton() {
    this.backButton = this.element.closest('dialog').querySelector('.btn-back');

    if (this.backButton) {
      this.backButton.addEventListener('click', () => {
        this.prevStep();
      });
    }
  }
}
