import { Controller } from '@hotwired/stimulus'
import { create, registerPlugin } from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

registerPlugin(FilePondPluginImagePreview);

export default class extends Controller {
  static targets = ['field']

  connect() {
    const options = {
      styleButtonRemoveItemPosition: 'right',
      credits: false,
      storeAsFile: true
    }

    this.filePond = create(this.fieldTarget, options)
  }
}
