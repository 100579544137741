import _ from 'lodash'
import { Controller } from '@hotwired/stimulus'
import { scrollToDirectoryView, updateQueryString } from 'mp/helpers/directory_helper'

export default class extends Controller {
  static targets = ['form', 'filterGroups']

  initialize() {
    this.adjustFilterPanel();

    this.debounceSubmit = _.debounce((e) => {
      this.requestSubmit();
    }, 350)
  }

  adjustFilterPanel() {
    const filterPanel = this.formTarget.querySelector('#directory-filters');

    if (filterPanel) {
      window.addEventListener('resize', () => {
        if (window.innerWidth > 1024) {
          filterPanel.style.transform = '';
          filterPanel.removeAttribute('open');
          filterPanel.setAttribute('close', '');
        }
      });
    }
  }

  connect() {
    this.handlePopState = (e) => {
      Turbo.visit(window.location, { action: 'replace' });
      if (!e.state.customHistory) {
        window.removeEventListener('popstate', this.handlePopState);
      }
    };
    window.addEventListener('popstate', this.handlePopState);
  }

  filterGroupsTargetConnected() {
    updateQueryString(this.formTarget);
  }

  navigate(e) {
    e.preventDefault();
    const regex = /page=(\d+)/; // Regular expression pattern to match the page value
    const match = regex.exec(e.currentTarget.getAttribute('href')); // Execute the regular expression on the link href
    const pageInputField = document.getElementById('page');
    pageInputField.value = match ? parseInt(match[1]) : 1; // Extract and assign page value from the matched result
    this.requestSubmit();
  }

  toggleFilters(e) {
    // Ability to show or hide filters that exceeds the Default number of initially visible filters

    const targetElement = e.target;
    const dataset = targetElement.dataset;
    const toggleTarget = dataset.viewMoreTarget || dataset.viewLessTarget;

    if (!toggleTarget) {
      return; // Handle cases where neither attribute is present
    }

    const showElement = document.getElementById(toggleTarget);
    const hideElement = document.querySelector(`[data-${dataset.viewMoreTarget ? 'view-less' : 'view-more'}-target="${toggleTarget}"]`);

    showElement.closest('.accordion').scrollIntoView({ behavior: 'smooth' });
    targetElement.classList.toggle('hidden');
    showElement.classList.toggle('hidden');
    hideElement.classList.toggle('hidden');
  }

  clearFilters() {
    this.clearForm(true);
    this.requestSubmit()
  }

  clearForm(skipBaseFilters = false) {
    let formElements = this.formTarget.elements;
    const fieldsToIgnore = ['authenticity_token', 'page'];
    const topLevelFilters = Array.from(document.querySelectorAll('.top-level-filter')).map((input) => input.name)
    fieldsToIgnore.push(...topLevelFilters);

    for (const formElement of formElements) {
      const { type, name } = formElement;
      if (skipBaseFilters && name.match(/^base\[.*\].*/)) { continue; }
      if (fieldsToIgnore.includes(name)) { continue; }

      switch (type.toLowerCase()) {
        case 'text':
        case 'password':
        case 'textarea':
        case 'hidden':
          formElement.value = '';
          break;
        case 'radio':
        case 'checkbox':
          if (formElement.checked)
          {
            formElement.checked = false;
          }
          break;
        case 'select-one':
        case 'select-multi':
          formElement.selectedIndex = -1;
          break;
        default:
          break;
      }
    }
  }

  requestSubmit() {
    this.formTarget.requestSubmit();
    scrollToDirectoryView();
  }

  selectDebounceSubmit(e) {
    let dropdown = e.currentTarget.closest('.dropdown');
    let selectElement = dropdown.querySelector('button.form-select');
    let selectFieldElement = dropdown.querySelector('input.form-select-field');
    let selectedValue = e.target.dataset.selectItemValue;

    if (selectFieldElement.value == selectedValue) {
      selectElement.textContent = selectElement.dataset.selectMenuValue;
      selectFieldElement.value = null;
    }
    else {
      selectElement.innerHTML = e.target.innerHTML
      selectFieldElement.value = selectedValue;
    }

    this.debounceSubmit(e);
  }
}
