import EmblaCarousel from "embla-carousel";
import i18n from 'src/mp/i18n';
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures"

const carouselDefaults = { align: "start", loop: false, containScroll: true, slidesToScroll: 1 };

const disablePrevNextBtnsFn = (prevBtn, nextBtn, embla) => {
  return () => {
    if (embla.canScrollPrev()) {
      prevBtn.removeAttribute("disabled");
    } else {
      prevBtn.setAttribute("disabled", "disabled");
    }

    if (embla.canScrollNext()) {
      nextBtn.removeAttribute("disabled");
    } else {
      nextBtn.setAttribute("disabled", "disabled");
    }
  };
};

// Do not init carousels in hidden elements, it will not work.
const isVisible = (carousel) => {
  return !(carousel.offsetParent === null);
};

// Init carousels
export function init(target) {
  const carousels = target.querySelectorAll('.carousel:not([data-initialized])');

  for (let carouselNode of carousels) {
    if (isVisible(carouselNode)) {
      carouselNode.dataset.initialized = true;
      const viewPort = carouselNode.querySelector(".carousel__viewport");

      if (viewPort) {
        const prevButton = viewPort.querySelector(".prev-arrow");
        const nextButton = viewPort.querySelector(".next-arrow");
        const count = viewPort.querySelector('.carousel__count');
        const dots = viewPort.querySelector(".carousel__dots");
        const axis = viewPort.dataset.axisDefault || "x";
        const loop = viewPort.dataset.loop == "true";
        const dragFree = viewPort.dataset.dragFree === 'true';

        const carousel = EmblaCarousel(
          viewPort,
          {
            ...carouselDefaults,
            loop: loop,
            axis: axis,
            dragFree: dragFree,
            slidesToScroll: 'auto',
            breakpoints: {
              '(max-width: 1279px)': { axis: 'x' },
            }
          },
          [WheelGesturesPlugin()]
        )
        const disablePrevAndNextBtns = disablePrevNextBtnsFn(prevButton, nextButton, carousel);
        if (count) {
          setupCount(carousel, count);
        }

        if (dots) {
          setupDots(carousel, dots);
        }

        carouselNode.dataset.axis = axis;

        prevButton.addEventListener("click", carousel.scrollPrev, false);
        nextButton.addEventListener("click", carousel.scrollNext, false);
        carousel.on("select", disablePrevAndNextBtns);
        carousel.on("init", disablePrevAndNextBtns);
        carousel.on('reInit', disablePrevAndNextBtns);
      }
    }
  }
}

const setupCount = (carousel, count) => {
  const updateCount = () => {
    const selected = carousel.selectedScrollSnap();
    count.innerText = i18n.t('media_gallery.count', { count: selected + 1, total: carousel.slideNodes().length });
  }

  carousel
    .on('init', updateCount)
    .on('select', updateCount);
}

const setupDots = (carousel, dotsContainer) => {
  const addDots = () => {
    dotsContainer.innerHTML = carousel.scrollSnapList().map(() => '<span class="dot"></span>').join('');
  };

  const updateDots = () => {
    const dots = Array.from(dotsContainer.querySelectorAll('.dot'));
    const dotCount = dots.length;
    const previous = carousel.previousScrollSnap();
    const selected = carousel.selectedScrollSnap();
    dots[previous].classList.remove('active');
    dots[selected].classList.add('active');

    if (dots.length < 5) { return; }

    dots.forEach((dot) => {
      dot.classList.remove('sm');
    });

    if (selected < 4) {
      dots[4].classList.add('sm');
    }
    if (selected >= 4) {
      dotsContainer.style.transform = `translateX(-${(selected - 3) * 13}px)`;
    } else {
      dotsContainer.style.transform = `none`;
    }

    if (selected >= 4) {
      dots[selected - 3].classList.add('sm');

      if (selected < dotCount - 1) {
        dots[selected + 1].classList.add('sm');
      }
    }
  };

  carousel
    .on('init', addDots)
    .on('init', updateDots)
    .on('select', updateDots);
};
