import { Controller } from '@hotwired/stimulus'
import { init as carouselInit } from 'mp/utilities/carousel';

export default class extends Controller {
  connect() {
    this.setOpenTarget();
    this.setCancelTarget();
  }

  setOpenTarget() {
    const targets = document.querySelectorAll(`[data-open-dialog=${this.element.dataset.name}]`);

    targets.forEach((opener) => {
      opener.addEventListener('click', () => {
        this.open();
      });
    });
  }

  setCancelTarget() {
    this.element.addEventListener('cancel', (e) => {
      if(e.target === e.currentTarget) {
        e.preventDefault();
        this.close();
      }
    });
  }

  open() {
    this.element.removeAttribute('close');
    this.element.showModal();

    // Init any carousels in the modal
    carouselInit(this.element);
  }

  close() {
    this.element.setAttribute('close', '');

    setTimeout(() => {
      this.element.close();
    }, 300);
  }
}
