import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['open']

  connect() {
    const dropdownMenu = this.element.querySelector('.dropdown-menu');

    if (dropdownMenu) {
      this.openTarget.addEventListener('click', () => {
        this.toggleDropdownMenu(dropdownMenu);
      });

      this.openTarget.addEventListener('focusout', () => {
        setTimeout(() => {
          dropdownMenu.classList.remove('show');
        }, 150)
      });
    }
  }

  toggleDropdownMenu(menu) {
    if (menu.classList.contains('show')) {
      menu.classList.remove('show');
    }
    else {
      menu.classList.add('show');
    }
  }
}
