const clickThumb = (evt) => {
  evt.preventDefault();
  const thumb = evt.target.closest("[data-gallery-thumb]");
  const galleryId = thumb.dataset.galleryThumb;
  const mediaType = thumb.dataset.galleryMediaType;
  const url = thumb.dataset.galleryFullPath;

  removeSelectedStyles(galleryId);
  addSelectedStyles(thumb);
  showFullImage(galleryId, url, mediaType)
};

const showFullImage = (galleryId, url, mediaType) => {
  const fullVideo = document.querySelector(`[data-gallery-video=${galleryId}]`)
  const fullImg = document.querySelector(`[data-gallery=${galleryId}]`);

  if(mediaType == 'youtube') {
    fullVideo?.setAttribute('src', url);
    fullVideo?.classList?.remove('hidden');
    fullImg.classList.add('hidden');
  }
  else if (mediaType == 'image') {
    fullImg.setAttribute('src', url);
    fullImg.classList.remove('hidden');
    fullVideo?.classList?.add('hidden');
  }
};

const removeSelectedStyles = (galleryId) => {
  const thumbs = document.querySelectorAll(`[data-gallery-thumb=${galleryId}]`);
  thumbs.forEach((thumb) => {
    const selectedClasses = thumb.dataset.gallerySelectedStyles;
    const classes = selectedClasses.split(" ");
    const img = thumb.querySelector("img");

    if (selectedClasses) {
      img.classList.remove(...classes);
    }
  });
};

const addSelectedStyles = (thumb) => {
  const selectedClasses = thumb.dataset.gallerySelectedStyles;
  const img = thumb.querySelector("img");

  if (selectedClasses) {
    const classes = selectedClasses.split(" ");
    img.classList.add(...classes);
  }
};

// Init galleries
export function init() {
  const thumbs = document.querySelectorAll("[data-gallery-thumb]");
  thumbs.forEach((thumb) => {
    // Set default selected styles
    if (thumb.dataset.gallerySelected == "selected") {
      addSelectedStyles(thumb);
    }
    thumb.addEventListener("click", clickThumb);
  });
}
