import _ from 'lodash'
import i18n from 'src/mp/i18n';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['addButton', 'field', 'preview', 'removeButton', 'title']

  connect() {
    this.youtubeHost = 'youtube';
    this.vimeoHost = 'vimeo';
    this.youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|shorts\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    this.vimeoRegex = /^(?:https?:\/\/)?(?:www\.)?(?:player\.)?vimeo\.com\/(?:\[a-z]*\/)*([0-9]{6,11})[?]?$/
    this.form = this.element.closest('form');

    this.fieldTarget.addEventListener('input', _.debounce((e) => {
      this.removePreview();
      this.validateURL(e.target.value), 500
    }));
  }

  validateURL(url) {
    if (url.length > 0) {
      let match;

      if (match = url.match(this.youtubeRegex)) {
        this.setPreview(match[1], this.youtubeHost);
      }
      else if (match = url.match(this.vimeoRegex)) {
        this.setPreview(match[1], this.vimeoHost);
      }
      else {
        this.removePreview();
        this.fieldTarget.classList.add('field_with_errors');
      }
    }
    else {
      this.removePreview();
      this.fieldTarget.classList.remove('field_with_errors');
    }
  }

  setPreview(id, host) {
    if (host === this.youtubeHost) {
      // Hack: This is a work around to get YouTube video title
      this.previewTarget.src = `//www.youtube.com/embed/${id}?rel=0&enablejsapi=1`;
      this.previewTarget.addEventListener('load', () => {
        this.previewTarget.contentWindow.postMessage('{"event":"listening","id":1}', '*');
      });

      window.addEventListener('message', (event) => {
        const data = JSON.parse(event.data);

        if (data.event === 'initialDelivery') {
          if(data.info.videoData.title) {
            this.addPreview(id, host, data.info.videoData.title);
          }
        }
      });
    }
    else if (host === this.vimeoHost) {
      fetch(`https://vimeo.com/api/v2/video/${id}.json`)
      .then((response) => response.json())
      .then((data) => {
          this.previewTarget.src = `//player.vimeo.com/video/${id}`;
          this.urlForPoster = data[0].thumbnail_large;
          this.urlForThumbnail = data[0].thumbnail_small;
          this.addPreview(id, host, data[0].title);
        })
      .catch(() => {
        this.removePreview();
      })
    }
  }

  addPreview(id, host, title) {
    this.videoId = id;
    this.videoHost = host;
    this.videoTitle = title;
    this.addButtonTarget.classList.remove('disabled');
    this.fieldTarget.classList.remove('field_with_errors');

    this.addButtonTarget.addEventListener('click', (e) => {
      this.addVideo(e);
    });
  }

  removePreview() {
    this.previewTarget.src = '';
    this.addButtonTarget.classList.add('disabled');
  }

  addVideo(e) {
    if (e.currentTarget.classList.contains('disabled')) { return; }

    this.titleTarget.innerHTML = this.videoTitle;
    this.removeButtonTarget.classList.remove('hidden');
    e.currentTarget.closest('dialog').querySelector('.btn-close').click();
    this.addFormData();

    this.removeButtonTarget.addEventListener('click', () => {
      this.removeVideo();
    });
  }

  removeVideo() {
    this.titleTarget.innerHTML = i18n.t('common.add_video_link');
    this.removeButtonTarget.classList.add('hidden');

    if(this.form) {
      this.form.elements['video_host'].value = '';
      this.form.elements['video_reference'].value = '';
      this.form.elements['video_url_for_poster'].value = '';
      this.form.elements['video_url_for_thumbnail'].value = '';
    }
  }

  addFormData() {
    if (!this.form) { return };

    this.form.elements['video_host'].value = this.videoHost;
    this.form.elements['video_reference'].value = this.videoId;

    if (this.videoHost === this.vimeoHost) {
      this.form.elements['video_url_for_poster'].value = this.urlForPoster;
      this.form.elements['video_url_for_thumbnail'].value = this.urlForThumbnail;
    }
  }
}
