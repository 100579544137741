import i18n from 'src/mp/i18n';
import { Controller } from '@hotwired/stimulus';
import { init as carouselInit } from 'mp/utilities/carousel';

export default class extends Controller {
  static targets = ['count', 'checkbox'];

  connect() {
    const dialog = document.querySelector('dialog[data-name="quote-request-modal"]');

    if (dialog) {
      // Init any carousels in the modal
      carouselInit(dialog);
    }
  }

  updateCount() {
    const count = this.checkboxTargets.filter((c) => c.checked).length;
    this.countTarget.textContent = i18n.t('recommended_listings.listings_selected', { count: count });
  }

  check() {
    this.updateCount();
  }
}
