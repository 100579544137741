// FIXME: There still seems to be some jank going on here in Firefox when clicking between links.

export function init() {
  const mainNavLinks = document.querySelectorAll("[data-inner-page-nav]>nav ul li a");
  const subheader = document.querySelector('[data-inner-page-nav]>nav');
  const subheaderHeight = subheader ? subheader.clientHeight : 0;
  let scrolling = false;

  function updateActiveNav() {
    if (scrolling) {
      scrolling = false;
      let fromTop = window.scrollY;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        let sectionTop = section.offsetTop;
        let sectionHeight = section.offsetHeight;
        let firstSection = section.classList.contains("first");

        if (
          (firstSection && fromTop < sectionTop) ||
          (fromTop >= sectionTop - subheaderHeight && fromTop < sectionHeight + sectionTop - subheaderHeight)
        ) {
          link.classList.add(...link.dataset.activeClasses.split(" "));
        } else {
          link.classList.remove(...link.dataset.activeClasses.split(" "));
        }
      });
    }
  }

  setInterval(updateActiveNav, 200);

  window.addEventListener("scroll", (event) => {
    scrolling = true;
  });
}
