import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['resendButton', 'resendTimerElement']

  connect() {
    if(this.hasResendButtonTarget) {
      this.handleResendButton();
    }
  }

  handleInput(e) {
    const input = e.currentTarget;
    const prevDivs = document.querySelectorAll('.otp-input');
    const divs = document.querySelectorAll(`.otp-input:nth-child(-n+${input.value.length + 2})`);

    prevDivs.forEach(function(div) {
      div.classList.remove('filled');
    });

    divs.forEach(function(div) {
      div.classList.add('filled');
    });

    if (input.value.length === 6) {
      input.classList.add('hide-cursor');
    }
    else {
      input.classList.remove('hide-cursor');
    }
  }

  handleResendButton() {
    let timeleft = 30;
    const resendButton = this.resendButtonTarget;
    const timerElement = this.resendTimerElementTarget;

    let resendTimer = setInterval(function(){
      timeleft--;
      timerElement.textContent = timeleft < 10 ? `0:0${timeleft}` : `0:${timeleft}`;

      if(timeleft <= 0) {
        timerElement.classList.add('hidden');
        resendButton.classList.add('text-primary');
        resendButton.classList.remove('pointer-events-none');
        clearInterval(resendTimer);
      }
    },1000);
  }
}
