export function init() {
  document.addEventListener('turbo:before-stream-render', function(event) {
    // Add a class to an element we are about to remove from the page
    // as defined by its "data-stream-exit-class"

    var elementToRemove = document.getElementById(event.target.target);

    if (elementToRemove && event.target.action == 'remove') {
      var streamExitClass = elementToRemove.dataset.streamExitClass;

      if (streamExitClass) {
        // Intercept the removal of the element
        event.preventDefault();
        elementToRemove.classList.add(streamExitClass);

        // Wait for its animation to end before removing the element
        elementToRemove.addEventListener('animationend', function() {
          event.target.performAction();
        })
      }
    }
  });
}
