import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.setOpenTarget();
    this.setCloseTarget();
  }

  setOpenTarget() {
    const targets = document.querySelectorAll(`[data-open-drawer=${this.element.id}]`);

    targets.forEach((opener) => {
      opener.addEventListener('click', () => {
        this.open();
      });
    });
  }

  setCloseTarget() {
    const targets = this.element.querySelectorAll('.btn-close');

    targets.forEach((opener) => {
      opener.addEventListener('click', () => {
        this.close();
      });
    });
  }

  open() {
    this.element.style.transform = 'none';
    this.element.setAttribute('open', '');
    this.element.removeAttribute('close');
  }

  close() {
    this.element.style.transform = '';
    this.element.removeAttribute('open');
    this.element.setAttribute('close', '');
  }
}
