import { Controller } from "@hotwired/stimulus"
import _ from 'lodash'

export default class extends Controller {
  static targets = ["container", "form", "recentPages", "resultsContainer", "searchField", "searchLink"]
  static values = {
    loadingAnimationPayload: String
  }

  connect() {
    this.debounceSubmit = _.debounce(function (e) {
      if (this.searchFieldTarget.value.length) {
        this.showLoadingAnimation()
        this.formTarget.requestSubmit()
      } else {
        this.clearResultsContainer()
      }
    }, 350);

    const searchDrawerToggle = document.getElementById('search-drawer-toggle');
    if (searchDrawerToggle) {
      searchDrawerToggle.addEventListener('click', () => this.searchFieldTarget.focus());
    }

    this.searchFieldTarget.addEventListener('focus', () => {
      this.resultsContainerTarget.classList.remove('md:hidden');
    });

    document.addEventListener('click', this.handleClick);
  }

  disconnect() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick = (event) => {
    if (event.target === this.searchFieldTarget) { return; }
    if (this.resultsContainerTarget.contains(event.target)) {
      this.searchFieldTarget.focus();
    } else {
      this.resultsContainerTarget.classList.add('md:hidden');
    }
  }

  recentPagesTargetConnected() {
    this.resultsContainerContent = this.resultsContainerTarget.innerHTML;
  }

  redirectToDirectoryPath(e) {
    if (this.searchLinkTarget && this.searchFieldTarget.value.length) {
      e.preventDefault();

      const url = new URL(this.searchLinkTarget.href);
      url.searchParams.append('search', this.searchFieldTarget.value)
      window.location.href = url.toString();
    }
  }

  showLoadingAnimation() {
    this.resultsContainerTarget.innerHTML = this.loadingAnimationPayloadValue
  }

  clearResultsContainer() {
    this.resultsContainerTarget.innerHTML = this.resultsContainerContent;
  }
}
