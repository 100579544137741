import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['countrySelect', 'stateSelect']
  statesPath = '/states'

  connect() {
    const options = {
      onItemAdd: (value) => {
        if (this.hasStateSelectTarget) {
          this.getStates(value);
        }
      },
    }

    this.countrySelect = new TomSelect(this.countrySelectTarget, options);

    if (this.hasStateSelectTarget) {
      this.stateSelect = new TomSelect(this.stateSelectTarget);

      if(this.countrySelect.items.length < 1) {
        this.stateSelect.disable();
      }
    }
  }

  getStates = async (country_id) => {
    if (country_id.length && !this.hasStateSelectTarget) { return; }

    this.stateSelect.loading = 1;
    this.stateSelect.clear(true);
    this.stateSelect.clearOptions();

    const response = await fetch(`${this.statesPath}?country_id=${country_id}`);
    const states = await response.json();

    if (states.length) {
      this.stateSelect.enable();
      this.stateSelect.loading = 0;
      this.stateSelect.addOptions(states);
      this.stateSelect.refreshOptions(false);
    }
    else {
      this.stateSelect.disable();
    }
  }
}
