import 'mp'
import 'mp/controllers'
import '@hotwired/turbo-rails'
import 'javascripts/fslightbox-pro.js'

import { Turbo } from '@hotwired/turbo-rails'
import { init as utilitiesInit } from 'mp/utilities'
import { init as polyFillsInit } from 'mp/polyfills'

Turbo.StreamActions.redirect = ->
  Turbo.visit(this.target)

document.addEventListener 'turbo:load', ->
  utilitiesInit(document)
  polyFillsInit()

document.addEventListener 'turbo:frame-render', ->
  utilitiesInit(document)
  polyFillsInit()
