import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggleSearch() {
    var navMenu = document.querySelector('#nav-menu');
    var searchField = document.querySelector('#header-search');

    if (navMenu.classList.contains('block')) {
      navMenu.classList.add('hidden');
      navMenu.classList.remove('block');
      searchField.classList.remove('hidden');
    }
    else {
      navMenu.classList.add('block');
      navMenu.classList.remove('hidden');
      searchField.classList.add('hidden');
    }
  }
}
