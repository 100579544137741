import { init as carouselInit } from './carousel';
import { init as galleryInit } from './gallery';
import { init as innerPageNavInit } from './inner_page_nav';
import { init as inlineTabsInit } from './inline_tabs';
import { init as removeItemInit } from './remove_item';

export function init(target) {
  carouselInit(target);
  galleryInit();
  innerPageNavInit();
  inlineTabsInit();
  removeItemInit();
}
