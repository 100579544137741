import _ from 'lodash'
import i18n from 'src/mp/i18n';
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'password', 'passwordConfirmation' ]

  connect() {
    if (this.hasPasswordTarget) {
      this.form = this.passwordTarget.closest('form');

      if (this.form) {
        this.form.addEventListener('submit', (e) => {
          if(this.validatePasswordMatch()) {
            return;
          }
          else {
            e.preventDefault();
          }
        });

        this.passwordTarget.addEventListener('input', _.debounce(() => {
          this.passwordConfirmationTarget.setCustomValidity('');
        }, 500));

        this.passwordConfirmationTarget.addEventListener('input', _.debounce(() => this.validatePasswordMatch(), 500));
      }
    }
  }

  validatePasswordMatch() {
    if (this.passwordTarget.value.length < 1 && this.passwordConfirmationTarget.value.length < 1) { return true; }

    if (this.passwordTarget.value === this.passwordConfirmationTarget.value) {
      this.passwordConfirmationTarget.setCustomValidity('');
      return true;
    }
    else {
      this.passwordConfirmationTarget.setCustomValidity(i18n.t('errors.models.mm_user.attributes.password_confirmation'));
      this.passwordConfirmationTarget.reportValidity();
      return false;
    }
  }
}
