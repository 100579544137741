import _ from "lodash"

const updateQueryString = (form) => {
  const url = new URL(window.location)
  const formData = new FormData(form)
  const keysToIgnore = ["authenticity_token"]
  const topLevelFilters = Array.from(document.querySelectorAll('.top-level-filter')).map((input) => input.name)
  keysToIgnore.push(...topLevelFilters)
  const params = url.searchParams

  Array.from(params.keys()).forEach((key) => params.delete(key))
  _.uniq(Array.from(formData.keys())).forEach((key) => {
    if (key.match(/base\[.+\]/) || keysToIgnore.includes(key)) {
      return
    }

    const values = _.compact(formData.getAll(key))

    if (values.length) {
      values.forEach((value) => {
        if (value.length) {
          params.append(key, value)
        }
      })
    }
  })

  window.history.pushState({ customHistory: true }, "", url)
}

const scrollToDirectoryView = () => {
  let element = document.getElementById('directory');

  if(element) {
    element.style.scrollMarginTop = `${document.querySelector('#header-bar').offsetHeight + 60}px`;

    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth' });
    }, 50);
  }
}

export { updateQueryString, scrollToDirectoryView }
