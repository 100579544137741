import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  pauseVideo(e) {
    const { frameId, host } = e.params;
    const frame = document.getElementById(frameId);
    if (host === 'youtube') {
      frame.contentWindow.postMessage(JSON.stringify({'event': 'command','func': 'pauseVideo','args': []}), "*");
    } else {
      frame.contentWindow.postMessage('{"method":"pause"}', '*');
    }
  }
}
