import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['iframe', 'image', 'mainItem', 'nextSlide', 'previousSlide']
  static values = { index: Number }

  initialize() {
    this.lightbox = new FsLightbox();
    this.galleryId = this.currentItem().dataset.videoGallery || this.currentItem().dataset.gallery;
    this.thumbs = [...document.querySelectorAll(`[data-gallery-thumb=${this.galleryId}]`)];
    const extractData = (attribute) => this.thumbs.map(thumb => thumb.dataset[attribute]);
    this.types = extractData('galleryMediaType');
    this.sources = extractData('galleryFullPath');
    this.thumbSources = extractData('galleryThumbPath');
    this.index = this.indexValue;
    this.updateIcons();
  }

  open() {
    const lightbox = this.lightbox;
    const { types, sources, thumbSources } = this;
    Object.assign(lightbox.props, { types, sources, thumbs: thumbSources, showThumbsOnMount: true});
    lightbox.open(this.index);
  }

  changeSlide(e) {
    this.setImage(parseInt(e.params.direction))
  }

  currentItem() {
    return this.iframeTarget.classList.contains('hidden') ? this.imageTarget : this.iframeTarget;
  }

  setImage(direction) {
    const index = this.index;

    if (direction === -1 && index > 0 || direction === 1) {
      if (index >= this.sources.length - 1 && direction === 1) {
        this.index = 0;
      } else {
        this.index = index + direction
      }

      this.thumbs[this.index].click();
      this.updateIcons();
    }
  }

  setIndex(e) {
    this.index = parseInt(e.params.index);
  }

  updateIcons() {
    if (window.innerWidth < 768) { return; }

    this.nextSlideTarget.classList.remove('hidden');
    if (this.index == 0) {
      this.previousSlideTarget.classList.add('hidden');
    } else {
      this.previousSlideTarget.classList.remove('hidden');
    }
  }
}
