import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this
      .element
      .querySelectorAll('.form-control')
      .forEach((element) => element.addEventListener('invalid', (e) => {
        this.showTabWithErrors(e.target);

        if (this.activeTabPane && !this.activeTabPane.contains(e.target)) {
          e.preventDefault();
        }
      }));

    const fieldWithErrors = this.element.querySelector('.field_with_errors .form-control');
    this.showTabWithErrors(fieldWithErrors);
  }

  showTabWithErrors(field) {
    if(!field) { return; }

    const tabPane = field.closest('.tab-pane');
    const inlineTabs = field.closest('.inline-tabs');
    const navItems = inlineTabs.querySelectorAll('.nav-item');
    const tab = Array.from(navItems).find((item) => item.querySelector(`[data-toggle-tab='#${tabPane.id}']`));

    if (this.activeTabPane && !this.activeTabPane.querySelector(':invalid')) {
      this.activeTabPane = null;
    }

    if(tab && !this.activeTabPane) {
      tab.click();
      this.activeTabPane = tabPane;
    }

    navItems.forEach((element) => element.addEventListener('click', () => {
      this.activeTabPane = null
    }));
  }
}
