import _ from 'lodash'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['locationSelect', 'timezoneSelect']
  addressPredictionsPath = '/google_maps'

  connect() {
    if (this.hasTimezoneSelectTarget) {
      this.initializeTimezoneSelect();
    }

    this.initializeLocationSelect();
  }

  initializeLocationSelect() {
    let currentLocation = this.locationSelectTarget.value;

    const options = {
      maxOptions: null,
      onInitialize: () => {
        if (currentLocation.length) {
          this.getPlace(currentLocation);
        }
      },
      load: function() {
        return true
      },
      onItemAdd: (value) => {
        if (this.hasTimezoneSelectTarget) {
          this.getPlace(value);
        }
      },
    }

    this.locationSelect = new TomSelect(this.locationSelectTarget, options);

    let locationField = this.locationSelectTarget.nextSibling.querySelector('.ts-control input[type=text]');

    if (locationField) {
      locationField.addEventListener('input', _.debounce((event) => this.getPredictions(event.target.value), 500));
    }
  }

  initializeTimezoneSelect() {
    const options = {
      maxOptions: null,
      render: {
        item: (data, escape) => {
          return `
            <div>
              <span>${escape(data.text)}</span>
              <span class='text-primary ml-1 cursor-pointer'>Change</span>
            </div>
          `;
        }
      }
    }

    this.timezoneSelect = new TomSelect(this.timezoneSelectTarget, options);
  }

  async getPredictions(input) {
    if (!input.length) { return; }

    this.locationSelect.open();
    this.locationSelect.loading = 1;

    const response = await fetch(`${this.addressPredictionsPath}?location=${input}`);
    const parsedResponse = await response.json();

    this.locationSelect.loading = 0;
    this.locationSelect.clearOptions();
    this.locationSelect.addOptions(parsedResponse);
    this.locationSelect.refreshOptions(false);
  }

  getPlace = async (place_id) => {
    if (!place_id.length && !this.hasTimezoneSelectTarget) { return; }

    const loadingDots = document.querySelector('.loading-dots');

    loadingDots.classList.toggle('hidden');
    this.timezoneSelect.loading = 1;
    this.timezoneSelect.clear(true);
    this.timezoneSelect.clearOptions();

    const response = await fetch(`${this.addressPredictionsPath}/${place_id}`)
    const data = await response.json();

    this.timezoneSelect.loading = 0;
    this.timezoneSelect.addOptions(data.country_zones);
    this.timezoneSelect.refreshOptions(false);
    this.setLocationFields(data);
    loadingDots.classList.toggle('hidden');
    this.timezoneSelect.setValue(data.timezone);
  }

  setLocationFields(data) {
    const form = this.element.closest('form');
    if (!form) { return; }

    form.querySelector('.address1-field').value = data.address1;
    form.querySelector('.city-field').value = data.city;
    form.querySelector('.country-id-field').value = data.country_id;
    form.querySelector('.state-id-field').value = data.state_id;
    form.querySelector('.postal-code-field').value = data.postal_code;
  }
}
