import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'viewCartButton', 'quantityField']

  connect() {
    this.quantityFieldTarget.addEventListener('change', this.updateAddToCartQuantity)
  }

  addToCart(e) {
    const form = this.formTarget;

    e.currentTarget.querySelector('.show-when-enabled').classList.add('hidden');
    e.currentTarget.querySelector('.show-when-disabled').classList.remove('hidden');

    if(form) {
      form.requestSubmit();
    }
  }

  updateAddToCartQuantity = (e) => {
    if(e.target.value.length <= 0) {
      e.target.value = 1;
    }
  }

  viewCartButtonTargetConnected(viewCartButton) {
    if (viewCartButton.hasAttribute('data-open-cart-drawer')) {
      const drawer = document.getElementById('cart-drawer');

      if (drawer) {
        drawer.style.transform = 'none';
        drawer.setAttribute('open', '');
        drawer.removeAttribute('close');
      }
    }
  }
}
