import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['pageBottom']

  initialize() {
    this.adjustPageHeader();
    this.adjustPageBottom();

    window.addEventListener('resize', () => {
      this.adjustPageBottom();
    }, true);
  }

  adjustPageHeader() {
    var prevScrollpos = window.scrollY;
    var header = document.querySelector('#header-bar');
    var bottomNav = document.querySelector('#info-box');

    window.onscroll = function() {
      var currentScrollPos = window.scrollY;

      if (prevScrollpos > currentScrollPos || currentScrollPos < 20) {
        header.classList.remove('scrolled');
        bottomNav.classList.remove('scrolled');
      } else {
        header.classList.add('scrolled');
        bottomNav.classList.add('scrolled');
      }

      prevScrollpos = currentScrollPos;
    }
  }

  adjustPageBottom() {
    let bufferHeight = 0;

    document.querySelectorAll('.page-bottom').forEach((element) => {
      bufferHeight += element.offsetHeight;
    });

    if (window.innerWidth < 1024 && bufferHeight > 1) {
      this.pageBottomTarget.style.height = `${bufferHeight - 0.5}px`;
    }
    else {
      this.pageBottomTarget.style.height = '';
    }
  }
}
