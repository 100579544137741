import 'tom-select'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    allowCreate: { type: Boolean, default: false },
    allowMultiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  }

  connect() {
    const disabled = this.disabledValue;

    let options = {
      create: this.allowCreateValue,
      allowEmpty: true,
      maxOptions: null,
      maxItems: this.allowMultipleValue ? null : 1,
      onInitialize: function() {
        if (disabled) {
          this.disable();
        }
      }
    }

    this.field = new TomSelect(this.element.getElementsByTagName('select')[0], options);
  }
}
